
import http from "../../../../../common/services/http";
import { defineComponent, computed, ref, onMounted } from "vue";
import moment from "moment";
import { format } from "date-fns";
import NgongVegBanner from "@/components/NgongVegBanner.vue";

export default defineComponent({
  components: { NgongVegBanner },
  setup() {
    const form = ref({});

    const weekly_outload = ref({});

    const varieties = ref([]);

    const processing = ref(false);

    const day = ref(0);
    const date = ref(new Date());

    function wednesday() {
      const date = new Date();
      const last = new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000);

      return date.getDay() < 3
        ? new Date(last.setDate(last.getDate() - (last.getDay() - 1) + 2))
        : new Date(date.setDate(date.getDate() - (date.getDay() - 1) + 2));
    }

    const start = ref(wednesday());
    const end = computed(
      () => new Date(new Date(start.value).getTime() + 6 * 24 * 60 * 60 * 1000)
    );

    function getDates(s: any) {
      const e = new Date(new Date(s).getTime() + 6 * 24 * 60 * 60 * 1000);

      const dates = [];
      let currentDate = moment(s);
      const eDate = moment(e);
      while (currentDate <= eDate) {
        dates.push(moment(currentDate).format("YYYY-MM-DD"));
        currentDate = moment(currentDate).add(1, "days");
      }

      return dates;
    }

    function fD(d: any, f = "PPPP") {
      return format(d, f);
    }

    function setDay(i = 0, d: any) {
      day.value = i;
      date.value = d;
    }

    function checkIfOrders(obj: any) {
      return JSON.stringify(obj) === "{}";
    }

    function fetchVarieties() {
      http.get(`/api/varieties?fetch=all`).then(res => {
        varieties.value = res.data;
      });
    }

    function filterVarieties(customer_id: any) {
      const variety = varieties.value.filter((customer: any) => {
        return customer.id == customer_id;
      });
      return variety[0]["name"];
    }

    function addCommas(value: number) {
      if (value !== undefined) {
        const value2dp = value.toFixed(2);
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    }

    function fetchWeeklyOutload() {
      processing.value = true;

      http
        .get(
          `/api/weekly-outload?start=${fD(start.value, "yyyy-MM-dd")}&end=${fD(
            end.value,
            "yyyy-MM-dd"
          )}`
        )
        .then(res => {
          weekly_outload.value = res.data;
          processing.value = false;
        });
    }

    onMounted(() => {
      fetchWeeklyOutload();
      fetchVarieties();
    });

    return {
      processing,
      fD,
      getDates,
      start,
      end,
      form,
      day,
      setDay,
      checkIfOrders,
      filterVarieties,
      weekly_outload,
      addCommas
    };
  }
});
